import './App.css';

function Contact() {
  return (
    <div className="App">
      <header className="App-header">
        <a href='mailto:kimathi.bwomono@gmail.com' className='project-link'>
          KIMATHI.BWOMONO@GMAIL.COM
          <div className="underliner"/>
          </a>
      </header>
    </div>
  );
}

export default Contact;