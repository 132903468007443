import './App.css';

function Work() {
  return (
    <header className="App-header">

    </header>
  );
}

export default Work;